import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// P1 importer BrowserRouter
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { GlobalContextProvider } from './contexts/GlobalContext';
import 'mapbox-gl/dist/mapbox-gl.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
