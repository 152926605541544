import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import Tableaujour from '../Tableaujour/Tableaujour';
import Indice from '../indice/Indice';
import './ForecastHome.css';
import globalContext from '../../contexts/GlobalContext';

function ForecastHome() {
  const { dataIri, setDataIri, currentPolygon, setDataWeekIri } =
    useContext(globalContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/iri/${currentPolygon}`)
      .then((res) => setDataIri(res.data));
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/iri/week/${currentPolygon}`
      )
      .then((res) => setDataWeekIri(res.data));
  }, [currentPolygon]);
  return (
    <div className="containerForecastHome">
      {dataIri ? (
        <>
          <Indice />
          <Tableaujour />
        </>
      ) : null}
    </div>
  );
}

export default ForecastHome;
