import React, { useContext } from 'react';
import './accueil.css';
import { NavLink } from 'react-router-dom';
import globalContext from '../../contexts/GlobalContext';
import logoVisioCrop from '../../img/logoVisioCrop.webp';

function Accueil() {
  const { getMyPosition } = useContext(globalContext);
  return (
    <div>
      <div className="position_question">
        <img className="questionPoint" src="./question.svg" alt="" />
      </div>
      <div className="container_accueil">
        <h1 className="titre">
          Forcast-<span className="fire">Fire</span>
        </h1>
        <p className="bigDescription">
          Forcast-Fire est une application vous permettant de prévenir du risque
          d’incendie à la journée sur vos exploitations, en fonction des
          différentes données référencées par nos soins.
        </p>
        <button className="buttonAccueil" type="button" onClick={getMyPosition}>
          Ma Position
        </button>
        <NavLink to="/map" className="buttonAccueil" type="button">
          Trouver un lieu
        </NavLink>
        <NavLink to="/mes-lieux" className="buttonAccueil" type="button">
          Mes lieux et favoris
        </NavLink>
        {/* Logo de visio Crop */}
        <p className="titleLogo">Ce site est produit par :</p>
        <a href="https://www.visio-crop.fr/" target="_blank" rel="noreferrer">
          <img
            src={logoVisioCrop}
            alt="Logo de VisioCrop"
            className="logoVisioCrop"
          />
        </a>
      </div>
    </div>
  );
}

export default Accueil;
