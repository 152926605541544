import React, { useContext, useState } from 'react';
// P1 Importation de la NavLink
import { NavLink, useNavigate } from 'react-router-dom';
import './login.css';
import axios from 'axios';
import globalContext from '../../../contexts/GlobalContext';

const Login = () => {
  const [email, setMail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(globalContext);
  // Route pour récupérer l'email et le password dans la BDD
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Si il manque l'email ou le mdp renvoyé une erreur
    if (!email || !password) {
      // eslint-disable-next-line no-alert
      alert('Veuillez fournir votre email et votre mot de passe');
    } else {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
          { email, password },
          { withCredentials: true }
        );
        setUser(data);
        // Le localstorage sert a stocké le role
        console.log(data);
        localStorage.setItem('role', data.role);
        localStorage.setItem('userId', data.id);
        localStorage.setItem('email', data.email);
        navigate('/');
        // Si l'email ou le mot de passe n'est pas reconnue dans la BDD renvoyé une erreur
      } catch (err) {
        alert("l'email ou le mot de passe est erroné");
      }
    }
  };
  return (
    <div>
      <h3 className="connection">Connexion :</h3>
      {/* Formulaire Login  */}
      <form onSubmit={handleSubmit}>
        {/* Input nom d'utilisateur */}
        <p className="emailP">Mail :</p>
        <label htmlFor="mail" className="nameLabel">
          <input
            type="email"
            id="mail"
            placeholder="example@gmail.com"
            value={email}
            onChange={(e) => setMail(e.target.value)}
            className="name"
          />
        </label>
        {/* Input mot de passe */}
        <p className="passwordP">Mot de passe :</p>
        <label htmlFor="password" className="passwordLabel">
          <input
            type="password"
            id="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="password"
          />
          <input type="submit" id="loginBtn" />
        </label>
      </form>
      {/* P2 Création du chemin pour relier la routes a la bonne page  */}
      <div className="signUpLogin">
        <NavLink className="signUp" to="/register">
          Inscrivez-vous
        </NavLink>
        <button type="submit" onClick={handleSubmit} className="login">
          Connectez-vous
        </button>
      </div>
      <NavLink to="/notPassword" className="forgotPassword">
        Mot de passe Oublié ?
      </NavLink>
    </div>
  );
};

export default Login;
