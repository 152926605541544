import React from 'react';
import { NavLink } from 'react-router-dom';
import './espaceutilisateur.css';

function espaceUtilisateur() {
  const email = localStorage.getItem('email');
  return (
    <div>
      <h1 className="espaceUtilisateurTitre">Bienvenue {email}</h1>
      <div className="lienButton">
        <NavLink to="/" className="bAccueil">
          Accueil
        </NavLink>
        <NavLink to="/mes-lieux" className="bFavoris">
          Mes favoris
        </NavLink>
        <NavLink to="/notPassword" className="bMdp">
          Modifier mon mot de passe
        </NavLink>
      </div>
    </div>
  );
}

export default espaceUtilisateur;
