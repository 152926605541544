import React, { useContext, useEffect, useState } from 'react';
import globalContext from '../../contexts/GlobalContext';
import './Indice.css';
import barometre from './circle.png';
import aiguille from './aiguille.png';

function Indice() {
  const { dataIri } = useContext(globalContext);
  const [angle, setAngle] = useState(-90);
  const [bgColor, setBgColor] = useState();
  const calculAngular = (c) => {
    setAngle(c * 1.8 + -90);
  };

  const getIriColor = (value) => {
    if (value < 30) {
      setBgColor('colorGreen dayIri');
    } else if (value <= 60) {
      setBgColor('colorOrange dayIri');
    } else {
      setBgColor('colorRed dayIri');
    }
  };

  useEffect(() => {
    calculAngular(dataIri.iri);
    getIriColor(dataIri.iri);
  }, [dataIri]);

  const angular = {
    transform: `rotate(${angle}deg)`,
    transition: '2s',
  };

  return (
    <div className="Indice">
      {dataIri ? (
        <>
          <h2 className="dayName">{dataIri.name}</h2>
          <div className="boxImg">
            <img src={barometre} className="barometre" alt="barometre" />
            <img
              src={aiguille}
              style={angular}
              className="aguille"
              alt="pointeur"
            />
          </div>
          <h3 className={bgColor}>{dataIri.iri}</h3>
          <h2 className="dayDate">{dataIri.shortDate}</h2>
        </>
      ) : null}
    </div>
  );
}

export default Indice;
