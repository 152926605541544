import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import './confirmPassword.css';

const BACK_URL = process.env.REACT_APP_BACKEND_URL;

const ConfirmPassword = () => {
  const { id, token } = useParams();
  const [resetPassword, setResetPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!confirmPassword) {
      alert('Veuillez remplir tous les champs');
    } else {
      try {
        const newPass = {
          id,
          token,
          password: confirmPassword,
        };
        console.log(confirmPassword);

        await axios
          .post(`${BACK_URL}/api/reset/confirmPassword`, newPass)
          .then((res) => {
            alert(res.data);
            confirmPassword('');
          })
          .catch((err) => {
            alert(err);
          });
      } catch (err) {
        alert(err.message);
      }
    }
  };
  return (
    <div className="ResetContainer">
      <label htmlFor="mail" className="nameLabel">
        {' '}
        Nouveau mot de passe :
        <input
          type="password"
          id="password"
          className="name"
          placeholder="******"
          value={resetPassword}
          onChange={(e) => setResetPassword(e.target.value)}
        />
      </label>{' '}
      <label htmlFor="mail" className="nameLabel">
        {' '}
        Confirmer nouveau mot de passe :
        <input
          type="password"
          id="password"
          className="name"
          placeholder="******"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </label>
      <button className="button" type="submit" onClick={handleSubmit}>
        Submit
      </button>{' '}
    </div>
  );
};

export default ConfirmPassword;
