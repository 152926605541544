import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './admin.css';

function Admin() {
  const navigate = useNavigate();
  useEffect(() => {
    // récupération du role stocké dans le localstorage
    const role = localStorage.getItem('role');
    // Si le role ne correspond pas alors redirigé vers la page accueil
    if (parseInt(role, 10) !== 1) {
      navigate('/');
    } else {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/users`, {
          withCredentials: true,
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            localStorage.removeItem('role');
            navigate('/');
          }
        });
    }
  });
  return (
    <div>
      <h1 className="titleAdmin">Page Admin</h1>
    </div>
  );
}

export default Admin;
