import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './signup.css';
import axios from 'axios';

const BACK_URL = process.env.REACT_APP_BACKEND_URL;

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Si le firstname, lastname, email, password, confirmpassword n'est pas remplis alors renvoyé une erreur
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      alert('Veuillez remplir tous les champs');
      // Si le password et le confirmpassword ne correspond pas alors renvoyé un erreur
    } else if (password !== confirmPassword) {
      alert('Votre mot de passe ne correspond pas');
    } else {
      // Si tout est bon alors envoyé le console.log
      console.log('data send to create user');
      try {
        // Et envoyé l'utilisateur dans la BDD
        const response = await axios.post(`${BACK_URL}/api/users`, {
          firstname: firstName,
          lastname: lastName,
          email,
          password,
        });
        // Si la res et 201 alors vidé tout les input
        if (response.status === 201) {
          alert('Compte crée');
          setFirstName('');
          setLastName('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          navigate('/login');
        } else {
          alert('Error');
        }
      } catch (err) {
        alert(err.message);
      }
    }
  };
  return (
    <div>
      <h3 className="inscription">Inscription :</h3>
      <form onSubmit={handleSubmit}>
        {/* Input nom d'utilisateur */}
        <p className="nameP">Prenom* :</p>
        <label htmlFor="prenom" className="nameLabel">
          <input
            id="prenom"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name="firstname"
            className="name"
          />
        </label>
        <p className="nameP">Nom* :</p>
        <label htmlFor="nom" className="nameLabel">
          <input
            id="nom"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name="lastname"
            className="name"
          />
        </label>
        <p className="emailP">Email* :</p>
        <label htmlFor="email" className="emailLabel">
          <input
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            className="name"
          />
        </label>
        {/* Input mot de passe */}
        <p className="passwordP">Mot de passe* :</p>
        <label htmlFor="password" className="passwordLabel">
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            className="password"
          />
        </label>
        <p className="passwordP">Comfirmé votre mot de passe* :</p>
        <label htmlFor="password" className="passwordLabel">
          <input
            id="password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="confirmPassword"
            className="password"
          />
          <input type="submit" id="loginBtn" />
        </label>
      </form>
      <div className="signUpLogin ">
        <NavLink className="signUp" to="/login">
          Connectez-vous
        </NavLink>
        <button className="login" type="submit" onClick={handleSubmit}>
          Inscription
        </button>
      </div>
    </div>
  );
};

export default SignUp;
