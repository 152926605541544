/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import globalContext from '../../contexts/GlobalContext';
import './listeLieux.css';
import deleteIcon from './cancel.png';
import updateIcon from './crayon.png';

function ListeLieux() {
  const { navigate, setCurrentPolygon, currentPolygon } =
    useContext(globalContext);

  const [listOfPolygon, setListOfPolygon] = useState();
  const [filterSearch, setFilterSearch] = useState();
  const [newPolygonName, setNewPolygonName] = useState();

  const [boolUpdate, setBoolUpdate] = useState(false);
  const [verifUpdate, setVerifUpdate] = useState(false);

  const [boolDelete, setBoolDelete] = useState(false);
  const [verifDelete, setVerifDelete] = useState(false);

  useEffect(async () => {
    if (boolUpdate === true) {
      await axios({
        method: 'put',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/aggro/${currentPolygon}`,
        data: {
          newName: newPolygonName,
        },
      });
      await axios({
        method: 'get',
        url: `${
          process.env.REACT_APP_BACKEND_URL
        }/api/aggro/lieux/${localStorage.getItem('userId')}`,
      }).then((res) => {
        setListOfPolygon(res.data);
      });
      setVerifUpdate(false);
      setBoolUpdate(false);
      setNewPolygonName('');
    }
  }, [boolUpdate]);

  useEffect(async () => {
    if (boolDelete === true) {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/aggro/${currentPolygon}`,
      });
      await axios({
        method: 'get',
        url: `${
          process.env.REACT_APP_BACKEND_URL
        }/api/aggro/lieux/${localStorage.getItem('userId')}`,
      }).then((res) => {
        setListOfPolygon(res.data);
      });
      setVerifDelete(false);
      setBoolDelete(false);
    }
  }, [boolDelete]);

  useEffect(() => {
    if (localStorage.getItem('userId')) {
      axios({
        method: 'get',
        url: `${
          process.env.REACT_APP_BACKEND_URL
        }/api/aggro/lieux/${localStorage.getItem('userId')}`,
      }).then((res) => {
        setListOfPolygon(res.data);
      });
    } else {
      navigate('/login');
    }
  }, []);

  const choosePolygon = (polygonId) => {
    setCurrentPolygon(polygonId);
    navigate('/forecasthome');
  };

  const updatePolygon = (polygonId) => {
    setVerifUpdate(true);
    setCurrentPolygon(polygonId);
  };

  const openUpdate = () => {
    setBoolUpdate(true);
    setVerifUpdate(false);
  };

  const deletePolygon = (polygonId) => {
    setVerifDelete(true);
    setCurrentPolygon(polygonId);
  };

  const openDelete = () => {
    setBoolDelete(true);
    setVerifDelete(false);
  };

  return (
    <div className="listeLieuxcontainer">
      <h1 className="pageTitle">Lieux sauvegardés</h1>
      <div>
        <input
          className="searchInput"
          type="search"
          placeholder="Filtrer en cherchant un mot"
          value={filterSearch}
          onChange={(e) => setFilterSearch(e.target.value)}
        />
      </div>
      <div className="flexTitles">
        <h2 className="thmTitle">Lieux</h2>
        <h2 className="thmTitle">Options</h2>
      </div>
      {verifUpdate === true ? (
        <div className="modalFormContainer">
          <div className="exitModal">
            <button
              className="exitButton"
              type="button"
              onClick={() => setVerifUpdate(false)}
            >
              X
            </button>
          </div>
          <div className="modalUpdateForm">
            <h2 className="modalUpdateTitle">Rennomer le lieu</h2>
            <input
              className="modalTextArea"
              type="text"
              value={newPolygonName}
              placeholder="ex: Parcelle n°4"
              onChange={(e) => setNewPolygonName(e.target.value)}
            />
            <input
              className="modalSubmit"
              type="button"
              value="Envoyer"
              onClick={openUpdate}
            />
          </div>
        </div>
      ) : null}
      {verifDelete === true ? (
        <div className="modalFormContainer">
          <div className="exitModal">
            <button
              className="exitButton"
              type="button"
              onClick={() => setVerifDelete(false)}
            >
              X
            </button>
          </div>
          <div className="modalDeleteForm">
            <h2 className="modalUpdateTitle">
              Voulez-vous vraiment supprimer ce lieu ?
            </h2>
            <div className="flexBool">
              <button
                type="button"
                className="modalDeleteBtn"
                onClick={openDelete}
              >
                Oui
              </button>
              <button
                type="button"
                className="modalDeleteBtn"
                onClick={() => setVerifDelete(false)}
              >
                Non
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="containerMap">
        {listOfPolygon ? (
          <>
            {listOfPolygon
              .filter(
                (member) =>
                  !filterSearch || member.firstname.includes(filterSearch)
              )
              .map((item, index) => (
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div id={index} role="button" className="flexItem">
                  <p
                    className="polygonName"
                    onClick={() => choosePolygon(item.polygonId)}
                  >
                    {item.firstname}
                  </p>
                  <div className="flexIcon">
                    <img
                      className="icons"
                      src={updateIcon}
                      alt="Modifier le nom de votre zone"
                      onClick={() => updatePolygon(item.polygonId)}
                    />
                    <img
                      className="icons"
                      src={deleteIcon}
                      alt="Supprimer votre zone"
                      onClick={() => deletePolygon(item.polygonId)}
                    />
                  </div>
                </div>
              ))}
          </>
        ) : (
          <h2 className="emptyArrayList">
            Vous n avez aucun lieu d enregistré !
          </h2>
        )}
      </div>
    </div>
  );
}

export default ListeLieux;
