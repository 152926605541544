// P1 importer le module Routes
import { useContext, useEffect } from 'react';
// Si le useNavigate ne marche pas faire npm/pnpm/yarn install react-router-dom@6
import { Routes, Route } from 'react-router-dom';
import SignUp from './components/identification/signUp/Signup';
import Login from './components/identification/login/Login';
import VerifyEmail from './components/identification/verifyEmail/verifyEmail';
import Nav from './components/menuburger/Nav';
import Accueil from './components/accueil/Accueil';
import Admin from './components/identification/gestion/Admin';
import Map from './components/map/Map';
import './app.css';
import Indice from './components/indice/Indice';
import ConfirmPassword from './components/confirmPassword/confirmPassword';
import EspaceUtilisateur from './components/identification/espaceUtilisateur/EspaceUtilisateur';
import globalContext from './contexts/GlobalContext';
import ListeLieux from './components/listeLieux/ListeLieux';
import ForecastHome from './components/ForecastHome/ForecastHome';

function App() {
  const { setUser } = useContext(globalContext);
  useEffect(() => {
    setUser(localStorage.getItem('role'));
  }, []);
  return (
    <div className="App">
      <Nav />
      {/* P2 Création des Routes avec un home principal */}
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/notPassword" element={<VerifyEmail />} />
        <Route path="/forecasthome" element={<ForecastHome />} />
        <Route
          path="admin"
          // element={user.role === 1 ? <Admin /> : <Navigate to="/" />}
          element={<Admin />}
        />
        <Route path="/map" element={<Map />} />
        <Route path="/indice" element={<Indice />} />
        <Route path="/mes-lieux" element={<ListeLieux />} />
        <Route path="/reset/:id/:token" element={<ConfirmPassword />} />
        <Route path="Espace-Utilisateur" element={<EspaceUtilisateur />} />
      </Routes>
    </div>
  );
}

export default App;
