import React, { useContext } from 'react';
import globalContext from '../../contexts/GlobalContext';
import './Tableaujour.css';
import good from './good.png';
import warning from './warning.png';
import danger from './danger.png';

function Tableaujour() {
  const { dataWeekIri } = useContext(globalContext);

  return (
    <div className="containerForecast">
      {dataWeekIri ? (
        <>
          <div className="titleOption white">
            <h2>Date</h2>
            <h2>Indice</h2>
            <h2>Conseil</h2>
          </div>
          <div className="mapContainerFc">
            {dataWeekIri.map((info) => (
              <div className="fcMapItem">
                <p className="fcDate white">{info.date}</p>
                <p className="fcIri white">{info.iri}</p>
                {info.iri < 29 ? (
                  <img src={good} className="fcConseil" alt="img-conseil" />
                ) : null}
                {info.iri > 30 && info.iri < 59 ? (
                  <img src={warning} className="fcConseil" alt="img-conseil" />
                ) : null}
                {info.iri > 60 ? (
                  <img src={danger} className="fcConseil" alt="img-conseil" />
                ) : null}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Tableaujour;
