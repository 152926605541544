import React, { useRef, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import './map.css';
import axios from 'axios';
import globalContext from '../../contexts/GlobalContext';

mapboxgl.accessToken =
  'pk.eyJ1IjoidmlzaW9jcm9wIiwiYSI6ImNsMTVoaDhmeDAzYzQzZHFzZGR3M3l1ZWwifQ.Tzbq0LEdpS4VuYaJLBYm3g';
function Map() {
  const { setCurrentPolygon, currentPolygon } = useContext(globalContext);
  const navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(1.01);
  const [lat, setLat] = useState(48.471);
  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [lng, lat],
      zoom,
    });

    if (!map.current) return; // wait for map to initialize
    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      'top-right'
    );

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
    });

    // if (myLatitude !== undefined) {
    //   setLng(myLongitude);
    //   setLat(myLatitude);
    // }
  }, [zoom]);

  const handleZoomIn = () => {
    if (zoom === 22) {
      setZoom(22);
    } else {
      setZoom(zoom + 1);
    }
  };
  const handleZoomOut = () => {
    if (zoom === 0) {
      setZoom(0);
    } else {
      setZoom(zoom - 1);
    }
  };

  const [name, setName] = useState();
  const [saveData, setSaveData] = useState(true);
  const [reqResp, setReqResponse] = useState();

  const handlSubmit = () => {
    if (!name || name.length < 4) {
      alert('4 caractères minimum ou champ manquant !');
    } else {
      try {
        setSaveData(!saveData);
      } catch (err) {
        console.log({ errMessage: err.message });
      }
    }
  };

  const [currentName, setCurrentName] = useState();
  useEffect(() => {
    if (saveData === false) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/aggro/`,
        data: {
          name,
          myPoint: [lat, lng],
          userId: localStorage.getItem('userId'),
        },
      }).then((res) => {
        setReqResponse(res.data);
        setSaveData(true);
        setCurrentPolygon(res.data.id);
        setCurrentName(name);
      });
    }
  }, [saveData]);

  const [isClickable, setIsClickable] = useState(false);
  useEffect(() => {
    if (saveData === false) {
      setIsClickable(true);
    }
  }, [reqResp]);

  return (
    <div className="appMap">
      <h2 className="titlePage">Trouver un lieu</h2>

      {currentPolygon ? (
        <p className="saveModal">{currentName} est sauvegardé</p>
      ) : null}

      <div className="container">
        <div className="mapAndButtonContainer">
          <div ref={mapContainer} className="map-container" />
          <div className="buttonContainer">
            <input
              className="mapButton"
              type="button"
              value="+"
              onClick={() => handleZoomIn(zoom)}
            />
            <input
              className="mapButton"
              type="button"
              value="-"
              onClick={() => handleZoomOut(zoom)}
            />
          </div>
        </div>
        <div className="formMapContainer">
          <input
            className="namePosition"
            type="text"
            name="lieux"
            id="lieuxId"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom de lieu à sauvgarder"
          />

          <button type="submit" className="submitButton" onClick={handlSubmit}>
            Enregistrer
          </button>

          {isClickable ? (
            <button
              className="enableButton"
              type="button"
              onClick={() => navigate('/forecasthome')}
            >
              Trouver mon IRI
            </button>
          ) : (
            <button disabled className="disableButton" type="button">
              Trouver mon IRI
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Map;
