import axios from 'axios';
import React, { useContext } from 'react';
import globalContext from '../../../contexts/GlobalContext';
import './verifyEmail.css';

const BACK_URL = process.env.REACT_APP_BACKEND_URL;

const NoPassword = () => {
  const { verifyEmail, setVerifyEmail } = useContext(globalContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!verifyEmail) {
      alert('Veuillez remplire tous les champs');
    } else {
      try {
        console.log(verifyEmail);

        await axios
          .get(`${BACK_URL}/api/reset/reset?email=${verifyEmail}`)
          .then(() => {
            alert(
              'Un Email vous a été envoyé à cette adresse. Cliquez sur le lien fournie pour réinitialiser votre mot de passe'
            );
            verifyEmail('');
          })
          .catch((err) => {
            alert(err);
          });
      } catch (err) {
        alert(err.message);
      }
    }
  };
  return (
    <div className="inputSend">
      <p>Veuillez entrer votre adresse Email</p>
      <label htmlFor="mail" className="nameLabel">
        {' '}
        Adresse mail :
        <input
          type="email"
          id="mail"
          placeholder="example@gmail.com"
          className="name"
          value={verifyEmail}
          onChange={(e) => setVerifyEmail(e.target.value)}
        />
      </label>
      <button className="button" type="submit" onClick={handleSubmit}>
        Envoyer{' '}
      </button>
    </div>
  );
};

export default NoPassword;
