import { NavLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import globalContext from '../../contexts/GlobalContext';
import './nav.scss';
import logo from '../../img/logo_Forcast-Fire.png';

export default function Nav() {
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const role = localStorage.getItem('role');
  const toogleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const { isConnected, setisConnected, user } = useContext(globalContext);
  // function quand le button déconnexion et cliqué
  // ça suprime tout ce qu'il y a dans le localstorage
  // et le setisConnectec repasse en false
  const thishandledeconnected = () => {
    localStorage.clear();
    setisConnected(false);
    axios.get = `${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`;
    navigate('/');
  };
  // Si il y a un role de détecter alors mettre le setisconnected en true
  // Si non le laisser en false
  useEffect(() => {
    if (localStorage.getItem('role')) {
      setisConnected(true);
    } else {
      setisConnected(false);
    }
  }, [user]);
  return (
    <div>
      <div className="navigation">
        <img src={logo} alt="Logo de l'application" className="logo" />
        <ul className={hamburgerOpen ? 'navUlClose' : 'navUlOpen'}>
          <NavLink className="lienNav" to="/" onClick={toogleHamburger}>
            Accueil
          </NavLink>
          <NavLink
            className="lienNav"
            to="/mes-lieux"
            onClick={toogleHamburger}
          >
            Lieux et Favoris
          </NavLink>
          <NavLink to="/map" className="lienNav" onClick={toogleHamburger}>
            Trouver un lieu
          </NavLink>
          <NavLink
            to="/mes-lieux"
            className="lienNav"
            onClick={toogleHamburger}
          >
            Mes lieux et favoris
          </NavLink>
          {/* Si le role et equal au bon role alors afficher cet route si non le laissé non afficher */}
          {parseInt(role, 10) === 1 && (
            <NavLink className="lienNav" to="/admin">
              Page admin
            </NavLink>
          )}
          {isConnected ? (
            <NavLink
              className="lienNav"
              to="/Espace-Utilisateur"
              onClick={toogleHamburger}
            >
              Espace Utilisateur
            </NavLink>
          ) : (
            <NavLink className="lienNav" to="/login" onClick={toogleHamburger}>
              Mon Compte
            </NavLink>
          )}
          {/* Boutons de déconnexion  */}
          {parseInt(role, 10) >= 0 && (
            <button
              type="button"
              className="deconnexion"
              onClick={thishandledeconnected}
            >
              déconnexion
            </button>
          )}
        </ul>
        <button
          type="button"
          className={hamburgerOpen ? 'modeOuvert' : 'modeFerme'}
          onClick={toogleHamburger}
        >
          <div className=" burger1" />
          <div className=" burger2" />
          <div className=" burger3" />
        </button>
      </div>
    </div>
  );
}
