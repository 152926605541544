import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const globalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [mylatitude, setMyLatitude] = useState();
  const [mylongitude, setMyLongitude] = useState();
  const [currentPolygon, setCurrentPolygon] = useState();
  const [isConnected, setisConnected] = useState(false);
  const [isDeconnected, setisDeconnected] = useState(false);
  const [user, setUser] = useState({ role: 0 });
  const [dataIri, setDataIri] = useState();
  const [dataWeekIri, setDataWeekIri] = useState();
  const navigate = useNavigate();
  const [verifyEmail, setVerifyEmail] = useState('');

  const getMyPosition = async () => {
    if ('geolocation' in navigator) {
      const coordinates = async (props) => {
        setMyLatitude(props.coords.latitude);
        setMyLongitude(props.coords.longitude);
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BACKEND_URL}/api/aggro/`,
          data: {
            myPoint: [`${props.coords.latitude}`, `${props.coords.longitude}`],
            userId: 1,
          },
        })
          .then((res) => {
            setCurrentPolygon(res.data.id);
            console.log(res.data.id);
            setTimeout(() => navigate('/forecasthome'), 5000);
          })
          .catch((err) => console.log(err.message));
      };
      navigator.geolocation.getCurrentPosition(coordinates);
    }
  };
  return (
    <globalContext.Provider
      value={{
        mylatitude,
        mylongitude,
        setMyLatitude,
        setMyLongitude,
        getMyPosition,
        currentPolygon,
        setCurrentPolygon,
        isConnected,
        setisConnected,
        user,
        setUser,
        dataIri,
        setDataIri,
        dataWeekIri,
        setDataWeekIri,
        navigate,
        isDeconnected,
        setisDeconnected,
        verifyEmail,
        setVerifyEmail,
      }}
    >
      {children}
    </globalContext.Provider>
  );
};

export default globalContext;
